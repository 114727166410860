<script lang="ts">
	import Paper, { Title, Subtitle, Content } from '@smui/paper';
	export let title = '';
	export let maxWidth = '500px';
</script>

<div class="paper-container">
	<Paper elevation={10} style="--centered-paper-max-width: {maxWidth}">
		<Title>{title}</Title>
		<div class="paper-contents">
			<slot />
		</div>
	</Paper>
</div>

<style>
	:global(.smui-paper) {
		width: 90%;
		max-width: var(--centered-paper-max-width, 500px);
		margin: auto;
	}
	:global(.smui-paper__title) {
		text-align: center;
	}
	.paper-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 80vh;
	}
	.paper-contents {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>
